.navbar-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-between;
  background-color: white;
  min-height: 3rem;
  position: relative;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
}

.navbar-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-title {
  font-size: 1.6rem;
  margin-left: 0.5rem;
  flex-grow: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  /* border: solid; */
}

.search-container {
  display: flex;
  align-items: center;
  /* max-width: 50%; */
  /* border: solid; */
}

.search-icon {
  cursor: pointer;
}

.search-input-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 100%;
  /* Adjust width as needed */
}

/* Styles for mobile screens */
@media (max-width: 600px) {
  .navbar-container-mobile {
    flex-direction: column;
    align-items: stretch;
    position: relative;
  }

  .navbar-title {
    font-size: 1.2rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    height: auto;
  }

  .search-container {
    /* margin-left: auto; */
  }

  .search-input-container-mobile {
    margin-left: auto;
    width: 5rem;
  }
}