/* styles.css */
.mArt {
  display: flex;
  justify-content: center;
  /* border: solid; */
}

.form {
  max-width: 50%;
}

.container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 85%;
}

.artistInfo,
.artistName,
.tags,
.imageSelection,
.fieldSet {
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}

.art,
.tag,
.image {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  /* font-weight: bold; */
  color: #555;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-root {
  font-size: 1rem;
}

/* File input styling */
.imageInput {
  display: block;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #f9f9f9;
  cursor: pointer;
}

.imageInput:hover {
  background-color: #e9e9e9;
}

.imageInput:focus {
  outline: none;
  border-color: #3f51b5;
}

/* Add hover effects for buttons */
button,
.MuiButton-root {
  background-color: #3f51b5;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover,
.MuiButton-root:hover {
  background-color: #303f9f;
}

/* .resizable-textarea .MuiInputBase-root {
  resize: both;
  overflow: auto;
} */