.close-button {
    background: none;
    border: none;
    color: white;
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 20%;
    background-color: #333;
    color: white;
    transition: left 0.5s ease;
    z-index: 999;
}

@media (max-width: 768px) {
    .sidebar {
        width: 50%;
    }
}

.sidebar.open {
    left: 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 8px 16px;
    text-align: left;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
    display: block;
}

.sidebar ul li a:hover {
    background-color: #575757;
}