.outerDiv {
  height: 98%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 1px;
  object-fit: cover;
  padding: 1%;
}

.imagediv {
  /* border: solid yellow; */
  height: 85%;
}

.bottomdiv {
  /* border: solid; */
  display: flex;
  align-items: center;
  /* max-height: 15%; */
  /* padding: 2%; */
  /* border: solid; */
}

.namediv {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* height: 2rem; */
  /* border: solid green; */
}

.sold {
  /* border: solid; */
  margin-left: auto;
  /* height: 50%; */
}