.mArt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.successMessage {
  color: rgb(12, 216, 12);
  margin-bottom: 1rem;
}

.heading {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
}

.captchaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* styles.css */
.mArt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: solid; */
}

.info {
  /* border: solid green; */
}

.heading {
  /* border: solid red; */
}

.form {
  max-width: 50%;
}

.container {
  /* border: solid yellow; */
  /* background-color: #fff; */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 85%;
}

.artName,
.artistName,
.fieldSet,
.userName,
.userEmail,
.message {
  display: flex;
  gap: 0.5rem;
  /* border: solid green; */
  /* padding: 0.5rem; */
  /* justify-content: space-around; */
  /* margin-bottom: 1.5rem; */
  /* padding: 0.5rem; */
  /* align-items: center; */
}

.art {
  font-size: 1.1rem;
  /* margin-bottom: 0.5rem; */
  font-weight: bold;
  color: #555;
  /* border: solid green; */
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-root {
  font-size: 1rem;
}

/* Add hover effects for buttons */
button,
.MuiButton-root {
  background-color: #3f51b5;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover,
.MuiButton-root:hover {
  background-color: #303f9f;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: auto;

  /* justify-items: center; */
}

.formSelection {
  width: 25%;
  padding: 0.7rem;
  margin: 0.5rem;
  background-color: #e9e9e9;
  text-align: center;
  border-radius: 10%;
  cursor: pointer;
  border: solid #e9e9e9;
}

.submit-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Success message styling */
.successMessage {
  position: fixed;
  top: 3rem;
  right: 1rem;
  background-color: rgba(18, 189, 5, 0.891);
  color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 3s ease, transform 2s ease;
}

.successMessage.hidden {
  opacity: 0;
  transform: translateX(100%);
  visibility: hidden;
}

/* CSS for the error message */
.errorMessage {
  position: fixed;
  top: 3rem;
  right: 1rem;
  background-color: rgba(255, 0, 0, 0.9);
  color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 3s ease, transform 2s ease;
}