.scroll-to-top {
  position: fixed;
  bottom: 9rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: skyblue;
  color: white;
  border: none;
  /* border-radius: 50%; */
  cursor: pointer;
  transition: opacity 0.3s, transform 50s;
  opacity: 0.6;
}

.scroll-to-top.fade-out {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.scroll-to-top-icon {
  font-size: 24px;
}

.scroll-to-top:hover {
  background-color: rgb(187, 224, 240);
  color: black;
  /* Optional: change text color on hover */
}