.mainDiv {
  padding: 1rem;
}

.sub1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align on mobile */
}

.sub1 .imgDiv {
  margin-bottom: 1rem; /* Space between image and name/info on mobile */
  max-width: 100%; /* Ensure image does not exceed container width */
}

.sub1 .imgDiv img {
  max-width: 100%; /* Ensure image resizes within its container */
  height: auto;
  max-height: 350px; /* Limit maximum height of artist image on larger screens */
}

.nameNInfo {
  /* max-width: calc(
    100% - 420px
  ); Adjust maximum width accounting for image and margin */
  margin-left: 1rem; /* Space between image and name/info on tablets and larger */
}

.sub2 {
  margin-top: 2rem;
}

.artsBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.artItem img {
  max-width: 100%; /* Ensure images fit within their containers */
  height: auto;
}

@media (min-width: 768px) {
  .sub1 {
    flex-direction: row; /* Arrange image and name/info side by side on tablets and larger */
    align-items: flex-start; /* Align items at the top on tablets and larger */
  }

  .sub1 .imgDiv {
    margin-right: 2rem; /* Space between image and name/info on tablets and larger */
    margin-bottom: 0; /* Remove bottom margin on tablets and larger */
    max-width: 300px; /* Limit maximum width of artist image on tablets and larger */
  }

  .nameNInfo {
    max-width: calc(
      100% - 320px
    ); /* Adjust maximum width accounting for image and margin */
  }
}
