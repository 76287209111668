.mainBox {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0.2rem;
  overflow: hidden;
  /* border: solid blue; */
}

.headerClass {
  display: flex;
  align-content: center;
  justify-content: space-between;
  position: relative;
  padding: 0.2rem;
  /* border: solid yellow; */
}

@keyframes dotFlashing {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.dot-container {
  display: flex;
  align-items: end;
  justify-content: end;
  align-content: end;
  font-family: 'Lobster', cursive;
}

.dot {
  width: .5rem;
  height: .5rem;
  margin: 0 5px;
  background-color: black;
  border-radius: 50%;
  animation: dotFlashing 1.5s infinite linear;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.pageHeading {
  /* position: absolute; */
  /* flex-grow: 1; */
  /* left: 50%;
  transform: translateX(-50%); */
  /* text-align: center; */
  /* border: solid; */
  /* min-width: 10px; */
}

.filterButton {
  /* position: absolute;
  right: 0.2rem; */
  /* max-width: 30%;
  margin-left: auto; */
}

/* 
.artCopy {
  max-width: 45%;
  justify-self: center;
  border: solid;
}
.mapDiv {
  height: 15rem;
  width: 12rem;
} */

/* @media (max-width: 600px) {
  .mapDiv {
    height: 13rem;
    width: 10rem;
  }
} */
/* 
.filterButton {
  border: solid;
  margin-left: auto;
} */