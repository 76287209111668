.mainDiv {
  padding: 1rem;
  /* border: solid; */
}

.sub1 {
  display: flex;
  padding: 1%;
  flex-direction: column;
  align-items: center; /* Center align on mobile */
  /* border: solid; */
}

.sub1 .imgDiv {
  /* border: solid red; */
  margin-bottom: 1rem; /* Space between image and name/info on mobile */
  max-width: 100%; /* Ensure image does not exceed container width */
}

.sub1 .imgDiv img {
  max-width: 100%; /* Ensure image resizes within its container */
  height: auto;
  max-height: 350px; /* Limit maximum height of artist image on larger screens */
}

.nameNInfo {
  /* max-width: calc(
    100% - 420px
  ); Adjust maximum width accounting for image and margin */
  margin-left: 1rem; /* Space between image and name/info on tablets and larger */
}

.sub2 {
  margin-top: 2rem;
}

.artsBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.artItem img {
  max-width: 100%; /* Ensure images fit within their containers */
  height: auto;
}

.nameNInfo {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  margin-left: 1rem; /* Space between image and name/info on tablets and larger */
  max-width: calc(
    100% - 320px
  ); /* Adjust maximum width accounting for image and margin */
  align-content: center;
}

.nameNInfo Button {
  /* margin-top: 1rem; Optional: Add some margin to the top of the button */
}

@media (min-width: 768px) {
  .sub1 {
    flex-direction: row; /* Arrange image and name/info side by side on tablets and larger */
    align-items: flex-start; /* Align items at the top on tablets and larger */
  }

  .sub1 .imgDiv {
    /* margin-right: 1rem;  */
    /* Space between image and name/info on tablets and larger */
    /* margin-bottom: 0; */
    /* Remove bottom margin on tablets and larger */
  }

  .sub1 .imgDiv img {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    /* border: solid; */
    /* height: auto; */
    max-height: 720px; /* Increase maximum height of the image on larger screens */
  }

  /* .nameNInfo {
    max-width: calc(
      100% - 320px
    ); 
    // Adjust maximum width accounting for image and margin
    align-content: flex-start;
  } */

  .nameNInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Center align items horizontally */
    margin-left: 1rem; /* Space between image and name/info on tablets and larger */
    max-width: calc(
      100% - 320px
    ); /* Adjust maximum width accounting for image and margin */
    align-content: start;
  }
}
