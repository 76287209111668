/* styles.css */
.slideshow-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: contain;
}

@media (max-width: 768px) {
  .slideshow-image {
    object-fit: cover;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}





.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  display: block !important;
  background: none !important;
  /* Remove default background */
}

.slick-prev:before,
.slick-next:before {
  content: none !important;
  /* Remove default content */
}

/* .slick-prev:before,
.slick-next:before {
  font-size: 50px;
  color: transparent; 
} */

.slick-dots li button:before {
  color: white;
}

.slick-dots .slick-active button:before {
  color: red;
}

/* 
.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  display: block !important;
  background: none !important; /* Remove default background  }  */

/* 
.slick-prev:before,
.slick-next:before {
  font-size: 50px;
  color: transparent; /* Make the default arrow icon transparent } */

/* .custom-arrow {
  display: block;
  background: transparent; /* Remove background 
  color: white;
  font-size: 24px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots .slick-active button:before {
  color: red;
} */