@import url('https://fonts.googleapis.com/css2?family=Metropolis:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

body {
    font-family: 'Arial', sans-serif;
}

.title {
    font-family: 'Metropolis', sans-serif;
    letter-spacing: 2px;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
}

.title2 {
    font-family: 'Metropolis', sans-serif;
    letter-spacing: 1.6px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
}

.title3 {
    font-family: 'Metropolis', sans-serif;
    letter-spacing: 1.4px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 400;
}

.title4 {
    font-family: 'Metropolis', sans-serif;
    letter-spacing: 1.2px;
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: 400;
}

.textBody {
    font-family: 'Arial';
    font-size: 1rem;
    /* text-transform: uppercase; */
    font-weight: 400;
    /* text-decoration: underline; */
}

@media (max-width: 768px) {
    .title {
        font-size: 1rem;
        letter-spacing: 1.6px;
        /* Smaller font size for mobile */
    }

    .title2 {
        font-size: 0.8rem;
        letter-spacing: 1.2px;
        /* Smaller font size for mobile */
    }

    .title3 {
        font-size: 0.6rem;
        letter-spacing: 1px;
        /* Smaller font size for mobile */
    }

    .title4 {
        font-size: 0.6rem;
        letter-spacing: 0.8px;
        /* Smaller font size for mobile */
    }

    .textBody {
        font-family: 'Arial';
        font-size: 1rem;
        /* text-transform: uppercase; */
        font-weight: 400;
    }
}