.outerbody {
  /* font-family: Arial, sans-serif; */
  /* background-color: #f0f0f0; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* min-height: 15vh; */
}

@media (max-width: 600px) {
  .outerbody {
    max-width: 80%;
  }
}

@media (min-width: 601px) {
  .outerbody {
    max-width: 45%;
  }
}

.container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px; /* Add a fixed width to the container */
  /* margin-top: 15rem; */
  /* margin-bottom: 15rem; */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align form elements */
}

h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  width: 100%; /* Make form group take full width */
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%; /* Make input fields take full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.password-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  padding-right: 50px; /* Make space for the button */
  box-sizing: border-box;
}

button {
  background-color: #61aeff;
  color: #fff;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #4ea3ff;
}

.new-to-website {
  margin-top: 20px;
  padding: 20px;
  /* background-color: #f8f9fa; */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.new-to-website button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%; /* Make button take full width */
  transition: background-color 0.3s ease; /* Add transition for smoother hover effect */
}

.new-to-website button:hover {
  background-color: #218838;
}

/* .toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: solid;
  cursor: pointer;
  font-size: 14px;
} */
